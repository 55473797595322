<template>
  <div class="chat-container">
    <div class="chat-header">
      <img :src="avatar_bot" class="header-avatar" alt="User Avatar" />
      <h2 class="chat-title">Chatbot</h2>
      <button @click="$emit('close')" class="close-button">&times;</button>
    </div>
    <div
      id="chatBox"
      class="chat-box flex flex-col bg-white shadow-lg rounded-lg w-full h-full space-y-6"
    >
      <div
        id="response"
        class="response-container border p-4 rounded-md overflow-y-auto flex-grow space-y-3"
      >
        <div
          v-for="message in messages"
          :key="message.id"
          :class="message.type"
        >
          <template
            v-if="
              message.type ===
              'user-message flex items-center space-x-3 my-2 justify-end'
            "
          >
            <div :class="message.textClass" class="order-1">
              <span v-html="message.text"></span>
            </div>
            <img
              :src="message.avatar"
              class="avatar w-10 h-10 rounded-full order-2"
              v-if="message.avatar"
            />
          </template>
          <template v-else>
            <img
              :src="message.avatar"
              class="avatar w-10 h-10 rounded-full"
              v-if="message.avatar"
            />
            <div :class="message.textClass">
              <span v-html="message.text"></span>
            </div>
          </template>
        </div>
      </div>
      <div id="loadingSpinner" class="hidden flex items-center justify-center">
        <div class="loader"></div>
        <span id="infoLoading" class="ml-2 text-gray-500"
          >À la recherche d'une réponse...</span
        >
      </div>
      <form @submit.prevent="sendMessage" class="flex space-x-3 p-4">
        <input
          type="text"
          v-model="messageText"
          placeholder="Votre question ici..."
          autocomplete="off"
          class="flex-grow border rounded-md p-2 focus:border-blue-400 focus:outline-none"
        />
        <button
          type="submit"
          class="send-button focus:outline-none transform transition-transform duration-300 hover:-translate-y-0.5"
        >
          <svg
            version="1.1"
            class="sc-user-input--send-icon enabled"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="37.393px"
            height="37.393px"
            viewBox="0 0 37.393 37.393"
            enable-background="new 0 0 37.393 37.393"
          >
            <g id="Layer_2">
              <path
                d="M36.511,17.594L2.371,2.932c-0.374-0.161-0.81-0.079-1.1,0.21C0.982,3.43,0.896,3.865,1.055,4.241l5.613,13.263
                L2.082,32.295c-0.115,0.372-0.004,0.777,0.285,1.038c0.188,0.169,0.427,0.258,0.67,0.258c0.132,0,0.266-0.026,0.392-0.08
                l33.079-14.078c0.368-0.157,0.607-0.519,0.608-0.919S36.879,17.752,36.511,17.594z M4.632,30.825L8.469,18.45h8.061
                c0.552,0,1-0.448,1-1s-0.448-1-1-1H8.395L3.866,5.751l29.706,12.757L4.632,30.825z"
              />
            </g>
          </svg>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import avatar_user from "../assets/avatar_user.jpg";
import avatar_bot from "../assets/avatar_bot.jpg";

export default {
  name: "ChatPopUp1",
  data() {
    return {
      messageText: "",
      messages: [
        {
          id: 0,
          text: "Bonjour ! Comment puis-je vous aider aujourd'hui ?",
          type: "bot-message flex items-center space-x-3 my-2",
          avatar: avatar_bot,
          textClass: "bg-blue-200 p-2 rounded-md text-left",
        },
      ],
      avatar_user,
      avatar_bot,
    };
  },
  methods: {
    // async sendMessage1() {
    //   const input = this.messageText.trim();
    //   if (input) {
    //     this.addMessage(input, "user");

    //     // Afficher le spinner de chargement
    //     document.getElementById("loadingSpinner").classList.remove("hidden");
    //     //document.getElementById("loadingSpinner").classList.add("hidden");
    //     // Vérifier les réponses prédéfinies

    //     // Envoyer la requête au backend
    //     const response = await axios.post("http://127.0.0.1:5000/ask", {
    //       question: input,
    //     });
    //     this.addMessage(response, "bot");
    //     // Cacher le spinner de chargement après avoir reçu la réponse
    //     document.getElementById("loadingSpinner").classList.add("hidden");
    //     this.messageText = "";
    //   }
    // },
    async sendMessage() {
      const input = this.messageText.trim();
      if (this.$store.state.topic === 0) this.showModal = true;
      else {
        if (input) {
          this.addMessage(input, "user");

          // Afficher le spinner de chargement
          document.getElementById("loadingSpinner").classList.remove("hidden");

          // Vérifier les réponses prédéfinies
          const predefinedResponse = this.getPredefinedResponse(input);
          if (predefinedResponse) {
            setTimeout(() => {
              this.addMessage(predefinedResponse, "bot");
              document.getElementById("loadingSpinner").classList.add("hidden");
            }, 500);
          } else {
            // Envoyer la requête au backend
            await axios
              .post("http://127.0.0.1:8000/ask", {
                question: input,
              })
              .then((response) => {
                // Traiter la réponse du backend
                const apiResponse = response.data.response; ///.replace(/\n/g, "<br>");

                this.addMessage(apiResponse, "bot");

                // Cacher le spinner de chargement après avoir reçu la réponse
                document
                  .getElementById("loadingSpinner")
                  .classList.add("hidden");
              })
              .catch((error) => {
                console.error("Erreur lors de la requête au backend:", error);
                // Gérer les erreurs, par exemple en affichant un message d'erreur à l'utilisateur
                this.addMessage(
                  "Erreur lors de la récupération de la réponse.",
                  "error"
                );

                // Cacher le spinner de chargement en cas d'erreur
                document
                  .getElementById("loadingSpinner")
                  .classList.add("hidden");
              });
          }

          this.messageText = "";
        }
      }
    },
    addMessage(text, sender) {
      const message = {
        id: this.messages.length,
        text: sender === "user" ? ` ${text}` : `${text}`,
        type:
          sender === "user"
            ? "user-message flex items-center space-x-3 my-2 justify-end"
            : "bot-message flex items-center space-x-3 my-2",
        avatar: sender === "user" ? avatar_user : avatar_bot,
        textClass:
          sender === "user"
            ? "bg-gray-200 p-2 rounded-md text-right"
            : "bg-blue-200 p-2 rounded-md text-left",
      };

      this.messages.push(message);

      // Faire défiler jusqu'au dernier message
      this.$nextTick(() => {
        const lastMessage =
          this.$el.querySelector("#response").lastElementChild;
        lastMessage.scrollIntoView({ behavior: "smooth" });
      });
    },
    getPredefinedResponse(userMessage) {
      const responses = {
        bonjour: "Bonjour !",
        "ca va":
          "Je suis un bot, donc je n'ai pas de sentiments, mais merci de demander !",
        "comment ca va":
          "Je suis un bot, donc je n'ai pas de sentiments, mais merci de demander !",
        "comment ca va?":
          "Je suis un bot, donc je n'ai pas de sentiments, mais merci de demander !",
        "comment ça va?":
          "Je suis un bot, donc je n'ai pas de sentiments, mais merci de demander !",
        "quel est ton nom?": "Je suis un chatbot sans nom.",
        "au revoir": "Au revoir ! Passez une bonne journée !",
        default: "Je suis désolé, je ne comprends pas votre question.",
      };

      const normalizedMessage = userMessage.toLowerCase().trim();
      return responses[normalizedMessage] || null;
    },
  },
};
</script>

<style scoped>
.chat-container {
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 350px;
  max-height: 80vh;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #87312d;
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.chat-title {
  font-size: 18px;
  font-weight: bold;
}

.chat-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.response-container {
  flex-grow: 1;
  overflow-y: auto;
}

.message {
  border-radius: 8px;
  padding: 8px;
  margin: 8px 0;
  font-size: 14px;
  max-width: 80%;
}

.user-message {
  align-self: flex-end;
  text-align: right;
  display: flex;
  align-items: center;
}

.bot-message {
  align-self: flex-start;
  text-align: left;
  display: flex;
  align-items: center;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 24px;
  height: 24px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.close-button {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: white;
}

.send-button {
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

form {
  display: flex;
  align-items: center;
  padding: 10px;
}
</style>
