<template>
  <div>
    <header
      class="d-flex align-items-center justify-content-center py-2 shadow-md mb-6 bg-light text-center"
    >
      <img src="@/assets/logo.png" alt="Logo" class="mr-4 w-16 h-16" />
      <h2 class="text-xl font-semibold">Chat Bot</h2>
    </header>
  </div>
</template>

<script>
export default {
  name: "AppHeader",
  methods: {},
};
</script>

<style scoped>
/* Style du label */
.b-form-group .form-label {
  text-align: center;
  display: block;
  margin-bottom: 1rem; /* Ajustez selon vos besoins */
  font-weight: bold;
}

/* Centrer le contenu du form-group */
.b-form-group {
  text-align: center;
}
</style>
