// src/store/index.js

import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    response: null,
  },
  mutations: {
    SET_REPONSE(state, payload) {
      state.response = payload;
    },
  },
  actions: {
    async send_message({ commit }, question) {
      const response = await axios.post(`http://127.0.0.1:5000/ask`, {
        question: question,
      });
      if (response.status < 300) {
        commit("SET_REPONSE", response.data.answer);
      }
    },
  },
  getters: {
    getCount: (state) => state.count,
  },
});

export default store;
