<template>
  <div
    id="chatBox"
    class="bg-gray-50 flex flex-col p-6 bg-white shadow-lg rounded-lg w-full sm:w-3/4 lg:max-w-2xl mx-auto space-y-6 transform transition-transform duration-300 hover:-translate-y-1 hover:shadow-xl"
  >
    <div
      id="response"
      class="border p-4 rounded-md overflow-y-auto h-80 sm:h-96 lg:h-128 space-y-3"
      style="height: 65vh"
    >
      <div v-for="message in messages" :key="message.id" :class="message.type">
        <template
          v-if="
            message.type ===
            'user-message flex items-center space-x-3 my-2 justify-end'
          "
        >
          <div :class="message.textClass" class="order-1">
            <span v-html="message.text"></span>
          </div>
          <img
            :src="message.avatar"
            class="avatar w-8 h-8 sm:w-10 sm:h-10 rounded-full order-2"
            v-if="message.avatar"
          />
        </template>
        <template v-else>
          <img
            :src="message.avatar"
            class="avatar w-8 h-8 sm:w-10 sm:h-10 rounded-full"
            v-if="message.avatar"
          />
          <div :class="message.textClass">
            <span v-html="message.text"></span>
          </div>
        </template>
      </div>
    </div>
    <div id="loadingSpinner" class="hidden flex items-center justify-center">
      <div class="loader"></div>
      <span id="infoLoading" class="ml-2 text-gray-500"
        >À la recherche d'une réponse...</span
      >
    </div>
    <form
      @submit.prevent="sendMessage"
      class="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-3"
    >
      <div class="w-full relative">
        <input
          type="text"
          v-model="messageText"
          placeholder="Votre question ici..."
          @input="autocomplete1"
          autocomplete="on"
          class="px-5 py-3 w-full border border-gray-300 rounded-md"
        />
        <ul
          v-show="suggestions.length"
          class="mt-1 w-full max-h-60 border border-gray-300 rounded-md bg-white absolute overflow-y-auto"
        >
          <li
            v-for="(suggestion, index) in suggestions"
            :key="index"
            @click="selectSuggestion(suggestion)"
            class="px-4 py-3 border-b border-gray-200 text-stone-600 cursor-pointer hover:bg-gray-100 transition-colors"
          >
            {{ suggestion }}
          </li>
        </ul>
      </div>

      <button
        style="background-color: #87312d"
        class="text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none transform transition-transform duration-300 hover:-translate-y-0.5"
      >
        Envoyer
      </button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import avatar_user from "../assets/avatar_user.jpg";
import avatar_bot from "../assets/avatar_bot.jpg";
import questions from "../data/QuesRep1.json";
export default {
  name: "AppChat",
  data() {
    return {
      suggestions: [],
      messageText: "",
      messages: [
        {
          id: 0,
          text: "Bonjour ! Comment puis-je vous aider aujourd'hui ?",
          type: "bot-message flex items-center space-x-3 my-2",
          avatar: avatar_bot,
          textClass: "bg-blue-200 p-2 rounded-md text-left",
        },
      ],
    };
  },
  methods: {
    async sendMessage() {
      const input = this.messageText.trim();
      if (this.$store.state.topic === 0) this.showModal = true;
      else {
        if (input) {
          this.addMessage(input, "user");

          // Afficher le spinner de chargement
          document.getElementById("loadingSpinner").classList.remove("hidden");

          // Vérifier les réponses prédéfinies
          const predefinedResponse = this.getPredefinedResponse(input);
          if (predefinedResponse) {
            setTimeout(() => {
              this.addMessage(predefinedResponse, "bot");
              document.getElementById("loadingSpinner").classList.add("hidden");
            }, 500);
          } else {
            // Envoyer la requête au backend
            await axios
              .post("http://157.173.126.179:80/ask", {
                question: input,
              })
              .then((response) => {
                // Traiter la réponse du backend
                const apiResponse = response.data.response;
                this.addMessage(apiResponse, "bot");

                // Cacher le spinner de chargement après avoir reçu la réponse
                document
                  .getElementById("loadingSpinner")
                  .classList.add("hidden");
              })
              .catch((error) => {
                console.error("Erreur lors de la requête au backend:", error);
                this.addMessage(
                  "Erreur lors de la récupération de la réponse.",
                  "error"
                );
                document
                  .getElementById("loadingSpinner")
                  .classList.add("hidden");
              });
          }

          this.messageText = "";
        }
      }
    },
    addMessage(text, sender) {
      const message = {
        id: this.messages.length,
        text: sender === "user" ? ` ${text}` : `${text}`,
        type:
          sender === "user"
            ? "user-message flex items-center space-x-3 my-2 justify-end"
            : "bot-message flex items-center space-x-3 my-2",
        avatar: sender === "user" ? avatar_user : avatar_bot,
        textClass:
          sender === "user"
            ? "bg-gray-200 p-2 rounded-md text-right"
            : "bg-blue-200 p-2 rounded-md text-left",
      };

      this.messages.push(message);

      // Faire défiler jusqu'au dernier message
      this.$nextTick(() => {
        const lastMessage =
          this.$el.querySelector("#response").lastElementChild;
        lastMessage.scrollIntoView({ behavior: "smooth" });
      });
    },
    getPredefinedResponse(userMessage) {
      const responses = {
        bonjour: "Bonjour !",
        "ca va":
          "Je suis un bot, donc je n'ai pas de sentiments, mais merci de demander !",
        "comment ca va":
          "Je suis un bot, donc je n'ai pas de sentiments, mais merci de demander !",
        "comment ca va?":
          "Je suis un bot, donc je n'ai pas de sentiments, mais merci de demander !",
        "comment ça va?":
          "Je suis un bot, donc je n'ai pas de sentiments, mais merci de demander !",
        "quel est ton nom?": "Je suis un chatbot sans nom.",
        "au revoir": "Au revoir ! Passez une bonne journée !",
        default: "Je suis désolé, je ne comprends pas votre question.",
      };

      const normalizedMessage = userMessage.toLowerCase().trim();

      // Vérifier les questions importées
      for (let question of questions) {
        if (question.Question.toLowerCase().includes(normalizedMessage)) {
          return question.Réponse;
        }
      }

      return responses[normalizedMessage] || null;
    },
    autocomplete1() {
      if (this.messageText.length > 2) {
        this.suggestions = questions
          .filter((q) =>
            q.Question.toLowerCase().includes(this.messageText.toLowerCase())
          )
          .map((q) => q.Question);
      } else {
        this.suggestions = [];
      }
    },
    selectSuggestion(suggestion) {
      this.messageText = suggestion;
      this.suggestions = [];
    },
  },
};
</script>

<style scoped>
body {
  font-family: "Arial", sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #947c69, #ded2c9);
}

#chatBox {
  animation: slideFromLeft 0.5s forwards;
}

#response {
  display: flex;
  flex-direction: column;
}

.message {
  border-radius: 8px;
  padding: 8px;
  margin: 8px 0;
  font-size: 14px;
  max-width: 60%;
}

.user-message {
  align-self: flex-end;
  text-align: right;
  display: flex;
  align-items: center;
}

.bot-message {
  align-self: flex-start;
  text-align: left;
  display: flex;
  align-items: center;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 24px;
  height: 24px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 640px) {
  #chatBox {
    padding: 1rem;
  }

  #response {
    height: 50vh;
  }

  .avatar {
    width: 30px;
    height: 30px;
  }

  .message {
    font-size: 12px;
  }
}

@keyframes slideFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
  