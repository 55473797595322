<template>
  <div>
    <img
      @click="afficher"
      :src="IconeAvatar"
      class="open-chat-avatar"
      alt="Open Chat"
    />
    <ChatPopUp v-if="showChat" @close="showChat = false" />
  </div>
</template>

<script>
import ChatPopUp from "./ChatPopUp1.vue";
import chatIcon from "../assets/avatar_bot.jpg"; // Remplacez par l'icône de chat appropriée
import chatClose from "../assets/avatar_close.jpg";
export default {
  components: {
    ChatPopUp,
  },
  data() {
    return {
      showChat: false,
      chatIcon,
      chatClose,
      IconeAvatar: chatIcon,
    };
  },
  methods: {
    afficher() {
      if (this.showChat) {
        this.showChat = false;
        this.IconeAvatar = this.chatIcon;
      } else {
        this.showChat = true;
        this.IconeAvatar = this.chatClose;
      }
    },
  },
};
</script>

<style>
.open-chat-avatar {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.open-chat-avatar:hover {
  transform: scale(1.1);
}
</style>