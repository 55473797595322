<template>
  <div
    id="app"
    class="font-sans min-h-screen p-5 bg-gradient-to-r from-gray-500 to-white"
  >
    <div class="page-container relative min-h-screen">
      <!-- Header -->
      <Header />
      <!-- Main content: chat -->
      <div class="flex flex-row justify-center items-start space-x-5 h-full">
        <Chat />
        <ChatPopUp />
        <!-- Main content: upload -->
        <!-- <Upload /> -->
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Chat from "./components/Chat1.vue";
import ChatPopUp from "./components/ChatPopUp.vue";
//import Upload from "./components/Upload.vue";
export default {
  data() {
    return {};
  },
  components: {
    ChatPopUp,
    Header,
    Chat,
    //Upload,
  },
};
</script>

<style scoped>
/* Add your CSS styles here */
.loader,
.upload-loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 24px;
  height: 24px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.font-sans {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.message {
  border-radius: 8px;
  padding: 8px;
  margin: 8px 0;
  font-size: 14px;
}
.user-message {
  background-color: #e0e0e0;
}
.bot-message {
  background-color: #d4edda;
}
body {
  background: linear-gradient(135deg, #947c69, #ded2c9);
  font-family: "Sans-serif";
  min-height: 100vh;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

* {
  transition: all 0.5s ease;
}

/* Header entrance effect */
@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

header {
  animation: slideDown 0.5s ease forwards;
}

/* Chatbox hover effect */
#chatBox:hover {
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
}

/* Upload section hover effect */
#uploadSection:hover {
  background-color: #f8f9fa;
  /* A slightly different shade of gray */
}

/* Active drop zone effect */
#uploadSection.dragover {
  border-color: #3498db;
  background-color: #ecf5ff;
  /* A light blue shade */
}

form {
  position: relative;
}

#suggestions {
  position: absolute;
  width: 100%; /* Adjust as needed */
  top: 100%; /* Position just below the input field */
  left: 0;
  z-index: 1000; /* Ensure it's above other content */
  max-height: 200px; /* Adjust based on your needs */
  overflow-y: auto; /* Enable vertical scrolling */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Adds a shadow for better visibility */
}

.suggestion-item {
  padding: 10px;
  cursor: pointer;
  background-color: white; /* Ensure readability */
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}

/* Fade-in effect for uploaded files */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#uploadedFiles > li {
  animation: fadeIn 0.5s ease forwards;
}

@keyframes slideFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

#uploadSection {
  transform: translateY(-50%); /* Centers it vertically */

  animation: slideFromRight 0.5s forwards;
}

#chatBox {
  /*... other styles ...*/
  animation: slideFromLeft 0.5s forwards;
}

.upload-loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#upload-loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.hidden {
  display: none !important;
}
</style>
